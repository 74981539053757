thWeather.config({
    apiUrl: "/wp-admin/admin-ajax.php",
    soir: '21:00:00',
    nuit: '03:00:00',
});

moment.locale(localizeParams.currentlanguage);

function initMeteo() {
    var momentList = ['nuit', 'matin', 'aprem', 'soir'];
    var day = [0, 1, 2];
    var keep_only_current_moment = false;

// gestion de celui qui est visible directement dans le menu principal
    var topweather = false;

    $('[data-weather]').each(function () {

        var _self = this;

        var city = $(this).data('weather');

        var spe_day = $(this).data('day');
        if (typeof spe_day != 'undefined') {
            day = [spe_day];
        }

        $.each(day, function (k, day) {
            var maxTemp = 0;
            var minTemp = 1000;
            var removeChecker = [];
            var already_res_for_day = false;

            $.each(momentList, function (k, periode) {
                //Données aux différents moments de la journée

                thWeather.get({
                    city: city,
                    moment: periode,
                    day: day,
                    lang: localizeParams.currentlanguage
                }, function (data) {
                    if (
                        typeof data !== "undefined"
                        && data !== null
                        && data
                        && (!keep_only_current_moment || (keep_only_current_moment && !already_res_for_day))
                    ) {
                        var date = moment(data.dt * 1000).format('dddd D MMM');

                        maxTemp = (data.temp_max > maxTemp) ? data.temp_max : maxTemp;
                        minTemp = (data.temp_min < minTemp) ? data.temp_min : minTemp;

                        var windspeed = Math.round((data.wind.speed * 3.6));

                        $('#day-' + day + ' .day', _self).html(date);

                        $('#day-' + day + ' .description', _self).html(data.weather[0].description);

                        $('#day-' + day + ' .' + periode + ' .temp .default', _self).html(data.degrees + "°C");
                        $('#day-' + day + ' .' + periode + ' .temp.min', _self).html(data.temp_min + "°C");
                        $('#day-' + day + ' .' + periode + ' .temp.max', _self).html(data.temp_max + "°C");
                        $('#day-' + day + ' .' + periode + ' .data-icon', _self).html(data.iconHtml);
                        $('#day-' + day + ' .' + periode + ' .vent .vent-container', _self).html(windspeed);
                        $('#day-' + day + ' .' + periode + ' .vent .direction', _self).text(data.winddirection.direction);
                        $('#day-' + day + ' .' + periode + ' .vent .icon-itineraire', _self).addClass('rotate-' + data.winddirection.deg);
                        if (data.rain) {
                            $('#day-' + day + ' .' + periode + ' .humid .rain-container', _self).html(data.rain['3h']);
                        }
                        if (data.main.humidity) {
                            $('#day-' + day + ' .' + periode + ' .humid .humid-container', _self).html(data.main.humidity);
                        }
                        $('#day-' + day + ' .' + periode + ' .pression .pressure-container', _self).html(windspeed);

                        if (!topweather) {
                            thWeather.get({
                                city: city,
                                moment: 'current',
                                day: day,
                                lang: localizeParams.currentlanguage
                            }, function (datacurrent) {
                                if (typeof datacurrent !== "undefined" && datacurrent !== null && datacurrent) {
                                    $('.data-topweather-weather.weather .data-icon').html(datacurrent.iconHtml);
                                    $('.data-topweather-weather.weather .temp').html(datacurrent.degrees + "°C");
                                    $('.data-topweather-weather.weather .tooltip').append('<br>' + datacurrent.weather[0].description);
                                    $('.data-topweather-weather.wind .windspeed').html(windspeed);
                                    // $('.data-topweather-weather.wind .winddirection').text(windirectiontrad[data.winddirection.direction]);
                                    $('.data-topweather-weather.wind .icon-itineraire').addClass('rotate-' + data.winddirection.deg);

                                    topweather = true;
                                }
                            });
                        }
                        already_res_for_day = true;
                    } else {
                        $('#day-' + day + ' .' + periode, _self).html('');
                        removeChecker.push(true);
                    }
                });

                if (removeChecker.length >= 4) {
                    $("#day-" + day, _self).remove();
                }
            });
        });
    });
}

initMeteo();