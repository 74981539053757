class ThAccordion {
    constructor(domNode) {
        this.rootEl = domNode;
        this.btnTrigger = this.rootEl.querySelector('button[aria-expanded]');

        const controlsId = this.btnTrigger.getAttribute('aria-controls');
        this.contentEl = document.getElementById(controlsId);

        this.isOpen = this.btnTrigger.getAttribute('aria-expanded') === 'true';

        // option pour avoir plusieurs accordéons ouverts au sein d'un groupe (par défaut un seul reste ouvert)
        this.allowMultiple = false;

        if (this.rootEl.dataset.allowMultiple && (this.rootEl.dataset.allowMultiple === 'true' || this.rootEl.dataset.allowMultiple === '1')) {
            this.allowMultiple = !!this.rootEl.dataset.allowMultiple;
        }

        this.groupId = this.rootEl.dataset.groupId;

        // add event listeners
        this.btnTrigger.addEventListener('click', this.onButtonClick.bind(this));

        this.calculateContentHeight();
        // Recalcule la hauteur du contenu de l'accordéon quand on resize la fenêtre
        window.addEventListener('resize', e => this.calculateContentHeight(e));

        // Accordéon ouvert à l'initialisation
        if (this.rootEl.classList.contains('is-open')) {
            this.toggle(!this.isOpen);
        }
    }

    // Recalcule la hauteur du contenu de l'accordéon quand on resize la fenêtre
    calculateContentHeight(e) {
        this.contentEl.style.setProperty('--height-open', this.contentEl.scrollHeight + 'px');
    }

    onButtonClick() {
        this.toggle(!this.isOpen);
    }

    toggle(isOpen) {
        // don't do anything if the open state doesn't change
        if (isOpen === this.isOpen) {
            return;
        }

        // update the internal state
        this.isOpen = isOpen;

        // handle DOM updates
        this.btnTrigger.setAttribute('aria-expanded', `${isOpen}`);
        if (isOpen) {
            this.calculateContentHeight();
            this.contentEl.removeAttribute('aria-hidden');
            this.contentEl.removeAttribute('inert');
            this.rootEl.classList.add('is-open');
        } else {
            this.contentEl.setAttribute('aria-hidden', 'true');
            this.contentEl.setAttribute('inert', '');
            this.rootEl.classList.remove('is-open');
        }
    }

    // Add public open and close methods for convenience
    open() {
        this.toggle(true);
    }

    close() {
        this.toggle(false);
    }
}