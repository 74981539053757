(function () {

    // Init des sliders
    const sliders = document.querySelectorAll('.js-slider-thematique');
    sliders.forEach(function(slider) {
        initSlider(slider);
    });

    function initSlider(slider) {
        const splide = new Splide(slider, {
            autoHeight: true,
        }).mount();

        // Navigation avec les onglets
        const tabs = slider.querySelectorAll('.tab-thematique');
        tabs.forEach(function(tab) {
            tab.addEventListener('click', function(e) {
                const id = Number(e.currentTarget.dataset.index);
                splide.go(id);
            });
        });

        // Active / désactive les onglets au changement de slide
        splide.on( 'active', function (Slide) {
            const id = Slide.index;
            const currentTab = slider.querySelector(`.tab-thematique[data-index="${id}"]`);
            if(currentTab) currentTab.classList.add('is-active');
        });

        splide.on( 'inactive', function (Slide) {
            const id = Slide.index;
            const lastTab = slider.querySelector(`.tab-thematique[data-index="${id}"]`);
            if(lastTab) lastTab.classList.remove('is-active');
        } );
    }

})();
