class overlayCustom {
    selector_overlay_custom = '.overlays_custom';
    overlayList = [];

    constructor() {
        this.parselinks();
    }

    parselinks(){
        let that = this;
        $('a[href^="#"]').each(function(){
            var overlayId = $(this).attr('href').replace('#', '');
            if (overlayId !== ""){
                that.overlayList.push(overlayId);
            }
        });
        this.createCustomOverlay();
    }

    createCustomOverlay(){
        let that = this;

        let thedata = {
            'action': 'handle_ajax_custom_overlay',
            overlayList: this.overlayList,
        }

        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            method: 'POST',
            data: thedata
        }).done(function(data) {
            if (data){
                $(that.selector_overlay_custom).html(data);

                $(that.selector_overlay_custom+' .frm_checkbox > label').append('<span></span>');
                $(that.selector_overlay_custom+' .frm_radio > label').append('<span></span>');

                $(that.selector_overlay_custom+' select').selectric();
            }

            th_overlay.parse($('body'));
            th_overlay.openOnLoad();
        });
    }

}

const overlaysCustom = new overlayCustom();